import React from 'react';
import HowItWorksRoot from '../../views/HowItWorks/HowItWorksRoot';
import { graphql } from 'gatsby';

const HowItWorksPage = (props) => (
	<React.Fragment>
		<HowItWorksRoot data={props.data} />
	</React.Fragment>
);

export default HowItWorksPage;

export const query = graphql`
	query HowItWorksPageE {
		howItWorksPage: contentfulHowItWorksPage {
			title
		}
	}
`;
